<template>
  <div class="home">
    <v-container class="my-6">
      <div>
        <v-row>
          <v-col cols="12" md="6">
            <div class="text-h5 text-md-h3">
              Inventario de Producto Terminado
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="6">
            <v-select
              v-model="warehouse_id"
              label="Almacén"
              :items="$warehouses"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="!!this.products">
            <v-data-table
              :headers="headers"
              :items="products"
              item-key="codigo"
              class="elevation-1"
              :search="search"
              :items-per-page="10"
              :loading="this.products_loading"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  label="Buscar"
                  class="mx-4"
                ></v-text-field>
                <v-dialog v-model="stockDialog" max-width="900px">
                  <v-card v-if="editedItem">
                    <v-card-title>
                      <span class="headline"
                        >Añadir stock a
                        {{ editedItem.product.description }}</span
                      >
                    </v-card-title>

                    <v-card-text>
                      <small>
                        Nota: Para descontar el stock, ingrese un valor negativo
                      </small>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.warehouse"
                              label="Almacén"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4" md="6">
                            <v-text-field
                              v-if="editedItem.updated_at"
                              :value="$formatDate(editedItem.updated_at)"
                              label="Ultima vez actualizado"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            <v-text-field
                              v-model="editedItem.stock"
                              label="Stock Actual"
                              readonly
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="4" md="4">
                            <v-form v-model="valid" v-on:submit.prevent>
                              <v-text-field
                                v-model="addedStock"
                                label="Nuevos productos"
                                :rules="[numberRule]"
                                hint="Si se quiere descontar ingresar valores negativos (Ej. -10)"
                                autofocus
                              ></v-text-field>
                            </v-form>
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            <v-text-field
                              v-model="editedItem.new_stock"
                              label="Nuevo stock"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="stockClosed">
                        Cancelar
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="saveStock">
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
              <template v-slot:item.updated_at="{ item }">
                {{ $formatDate(item.updated_at) }}
              </template>

              <template v-slot:item.actions="{ item, row }">
                <v-icon medium class="mr-2" @click="openStockDialog(item, row)">
                  mdi-plus-thick
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    products: [],
    search: '',
    editedItem: {
      product: {}
    },
    stockDialog: false,
    addedStock: '',
    warehouse_id: 1,
    products_loading: true,
    numberRule: v => {
      if (!v.trim()) return true
      if (!isNaN(parseFloat(v))) return true
      return 'Tiene que ser un número'
    },
    valid: true
  }),
  watch: {
    dialog(val) {
      val || this.close()
    },
    addedStock(val) {
      if (val === '') {
        this.editedItem.new_stock = this.editedItem.stock
        return
      }
      this.editedItem.new_stock = this.editedItem.stock + parseInt(val)
      console.log('New value for add:', val, this.editedItem.new_stock)
    },
    warehouse_id(val) {
      this.products_loading = true
      this.$makeGetRequest(`/api/finishedproducts/warehouse/${val}`).then(
        response => {
          this.products = response.data.data
          this.products_loading = false
          console.log(response)
        }
      )
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'Código',
          align: 'start',
          sortable: true,
          value: 'product.code'
        },
        { text: 'Almacén', value: 'warehouse' },
        {
          text: 'Descripción',
          value: 'product.description',
          filtereable: true,
          sortable: false
        },
        { text: 'Unidad', value: 'unit', sortable: false },
        {
          text: 'Ultima vez actualizado',
          value: 'updated_at',
          align: 'center'
        },
        { text: 'Stock', value: 'stock' },
        { text: 'Acciones', value: 'actions', sortable: false, align: 'center' }
      ]
    }
  },
  mounted: function() {
    let wareh_id = 1
    if (this.$store.getters.user.name.search('Renato') !== -1) {
      wareh_id = 2
      this.warehouse_id = wareh_id
    }
    this.$makeGetRequest(`/api/finishedproducts/warehouse/${wareh_id}`).then(
      response => {
        this.products = response.data.data
        this.products_loading = false
        console.log(response)
      }
    )
  },
  methods: {
    openStockDialog(item, row) {
      console.log(item, row)
      this.editedItem = Object.assign({}, item)

      this.stockDialog = true
    },
    stockClosed() {
      this.stockDialog = false
      this.editedItem = { product: {} }
      this.addedStock = ''
    },
    async saveStock() {
      if (this.editedItem.new_stock < 0) {
        this.$showMessage('El producto tendrá stock negativo', 'error')
        return
      }
      if (this.valid) {
        await this.$makePostRequest('api/finishedproducts/addstock', {
          product_id: this.editedItem.id,
          addedStock: this.addedStock
        })
        console.log('Saved stock')
        this.products_loading = true
        this.$makeGetRequest(
          `/api/finishedproducts/warehouse/${this.warehouse_id}`
        ).then(response => {
          console.log('Update data')
          this.products = response.data.data
          this.$showMessage('Stock Actualizado', 'success')
          this.products_loading = false
        })
        this.editedItem = { product: {} }
        this.addedStock = ''
        this.stockDialog = false
      }
    }
  }
}
</script>
